<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="container">
      <div class="con-l">
        <div class="mod mod1">
          <div class="s-pub-tt flex">
            <span class="bt">乡贤人数</span>
          </div>
          <ul class="mod1-list flex-1">
            <li v-for="item in xxList" :key="item.index">
              <div class="tit">{{ item.xxTitle }}</div>
              <div class="txt">
                <span>{{ item.xxNum }}</span
                >人
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="cen-map">
          <img src="https://home/ResImg/gldy-jk.gif" class="pic" alt="" />
          <!-- <div
            class="item item1"
            :style="indexNum == index ? 'opacity:1' : ''"
            v-for="(item, index) in tipsList"
            :key="index"
          >
            <div class="top">
              <div class="info">
                <div class="flex flex-align-center">
                  <h6 class="name">{{ item.name }}</h6>
                </div>
                <div class="tel">
                  <div
                    :class="item.money >= 500 ? 'warning1' : 'warning2'"
                  ></div>
                  <p style="color: #fff">捐款金额:</p>
                  <p
                    class="tel-e"
                    :style="item.money >= 500 ? 'color:#45f5ff' : 'color:#red'"
                  >
                    {{ item.money }}
                  </p>
                  万元
                </div>
              </div>
            </div>
            <div class="txt">
              <em style="color: #fff">捐款人:</em>

              {{ item.pel }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod mod6">
          <div class="s-pub-tt flex">
            <span class="bt">乡贤贡献列表</span>
          </div>
          <div class="s-scroll-list flex-1 flex flex-v">
            <div class="row row-t">
              <div style="width: 40%; text-align: center">
                {{ xxAbout.head1 }}
              </div>
              <div style="width: 20%; text-align: center">
                {{ xxAbout.head2 }}
              </div>
              <div style="width: 40%; text-align: center">
                {{ xxAbout.head3 }}
              </div>
            </div>
            <vue-seamless-scroll
              :data="xxAboutList"
              :class-option="scrollOption"
              class="flex-1"
            >
              <div class="scrollbox">
                <div
                  class="row row-b"
                  v-for="(item, index) in xxAboutList"
                  :key="index"
                >
                  <div style="width: 40%; text-align: center">
                    {{ item.title }}
                  </div>
                  <div style="width: 20%; text-align: center">
                    {{ item.xMan }}
                  </div>
                  <div style="width: 40%; text-align: center">
                    {{ item.unit }}
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
          <!-- <ul class="mod6-list flex-1">
            <li class="item" v-for="(item, index) in monitorList" :key="index">
              <img :src="item.img" class="img img_full" alt="" />
              <h6 class="title">{{ item.title }}</h6>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_sm.vue";
import axios from "axios";

// echarts.registerMap('china', china);
export default {
  data() {
    return {
      headTitle: "",
      xxList: [],
      xxAbout: {},
      xxAboutList: [],
      indexNum: 0, //随机数,
      // tipsList:[],
      tipsList: [], //冒泡数组
      xxAboutList: [],
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    this.getData();
    this.getxxList();
    this.getxxAbout();
  },
  mounted() {
    // setInterval(() => {
    //   // this.indexNum = Math.floor(Math.random() * (this.tipsList.length - 1));
    //   if (this.indexNum >= 12) {
    //     this.indexNum = 0;
    //     return;
    //   } else {
    //     this.indexNum++;
    //     console.log("this.indexNum===>", this.indexNum);
    //   }
    // }, 3000);
  },
  beforeDestroy() {
    // clearInterval();
  },
  methods: {
    getData() {
      axios
        .get("/10xxwh.json", {
          params: {
            // timestamp:new Date().getTime()
            random: Math.random(),
          },
        })
        .then((res) => {
          // 处理响应
          // console.log("数据:"+res.data);
          let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
          // console.log(dataObject.data);
          //
          let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
          // console.log(jsonString) // 输出字符串类型的 JSON 数据
          let jsonObject = JSON.parse(jsonString);
          this.webData = jsonObject;
          //标题
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },
    async getxxList() {
      const res = await axios.get("/10xxwh.json");
      let dataObject = res.data;
      console.log("res.data====>", res.data);
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.headTitle = jsonObject.bigTitle;
      this.xxList = jsonObject.xxList;
      this.tipsList = jsonObject.centerList;
      console.log("xxList===>", this.xxList);
      console.log("tipsList===>", this.tipsList);
    },
    async getxxAbout() {
      const res = await axios.get("/10xxwh.json");
      let dataObject = res.data;
      console.log("res.data====>", res.data);
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.xxAbout = jsonObject.xxAbout;
      this.xxAboutList = jsonObject.xxAbout.xxList;
      console.log("this.xxAboutList===>", this.xxAboutList);
    },
  },
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: url(../assets/images/map_xc.jpg) no-repeat center;
  /* background: url(../assets/images/jy_01.jpg) no-repeat center; */
  /* background: url(https://home/ResImg/map.png) no-repeat center; */
  background-size: cover;
  background-color: #0a224b;
}

.mod {
  margin-top: 10%;
  margin-bottom: 30px;
  /* height: calc(33.3% - 20px); */
  display: flex;
  flex-direction: column;
}
.mod:last-child {
  bottom: 0;
}
.mod1 {
  height: calc(60% - 10px);
}
.mod6 {
  margin-top: 80%;
  height: calc(50.5% - 20px);
}
.mod1-list {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 30px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.mod1-list li {
  width: 45%;
  height: 10%;
  background-color: #08172e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  font-size: 32px;
  color: #fff;
}
.mod1-list .tit {
  font-size: inherit;
}
.mod1-list .txt {
  font-size: inherit;
}
.mod1-list .txt span {
  font-size: 40px;
  color: #45f5ff;
  font-family: "simpds";
  padding-right: 8px;
}
.mod1-list .txt i {
  font-family: "simpds";
}

.mod6-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px;
}
.mod6-list .item {
  position: relative;
  overflow: hidden;
}
.mod6-list .title {
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 34px;
  padding: 15px 10px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #0a1f3e;
}

.cen-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding: 0 7%;
}
.cen-list li {
  text-align: center;
  width: 30%;
  margin-top: 20px;
}
.cen-list .title {
  font-size: 44px;
  color: #fff;
  font-family: "RuiZiZhenYan";
  height: 70px;
  line-height: 70px;
  background: url(../assets/images/jt_01.png) no-repeat center;
  background-size: 100% 100%;
}
.cen-list .title em {
  font-size: 30px;
  font-family: "RuiZiZhenYan";
}
.cen-list .txt {
  font-size: 52px;
  color: #45f5ff;
  font-family: "PangMenZhengDao";
  margin-top: 10px;
}

.cen-map {
  position: relative;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.cen-map .pic {
  width: 100%;
  height: 100%;
  margin-left: 2%;
  max-width: initial;
}
.cen-map .point {
  position: absolute;
}
.cen-map .point .tag {
  font-size: 26px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px;
  border-radius: 8px;
  line-height: 55px;
  border: 1px solid #45f5ff;
  background-color: #082658;
}
.cen-map .point .icon {
  width: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.cen-map .point1 {
  left: 25%;
  top: 70%;
}
.cen-map .point2 {
  left: 20%;
  top: 55%;
}
.cen-map .point3 {
  left: 53%;
  top: 55%;
}
.cen-map .point4 {
  left: 80%;
  top: 20%;
}
.cen-map .item1 {
  top: 30%;
  left: 70%;
}
.cen-map .item {
  position: absolute;
  width: 600px;
  height: 300px;
  border-radius: 12px 12px 12px 12px;
  opacity: 0;
  border: 2px solid;
  border-image: linear-gradient(
      89deg,
      rgba(69.00000348687172, 245.00000059604645, 255, 1),
      rgba(69.12294209003448, 242.13410317897797, 251.96428209543228, 0),
      rgba(69.00000348687172, 245.00000059604645, 255, 1)
    )
    2 2;
  background: url(../assets/images/titltBg.png) no-repeat center;
  background-size: 100% 100%;
  z-index: 50;
  padding: 15px;
  /* border-radius: 10px;
  opacity: 1;
  background: rgba(0, 48, 104, 0.55);
  box-sizing: border-box;
 
  position: absolute;
  */
}
.cen-map .item .top {
  display: flex;
  align-items: center;
}

.cen-map .item .info {
  flex: 1;
  overflow: hidden;
}
.cen-map .item .name {
  margin-left: 20px;
  width: 140px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: url(../assets/images/xxTitle.png) no-repeat center;
  background-size: 100% 100%;
  font-size: 28px;
  color: #fff;
}

/* .cen-map .item .tag {
  display: inline-block;
  vertical-align: middle;
  padding: 0 15px;
  font-size: 24px;
  color: #fff;
  border-radius: 21px;
  line-height: 36px;
  height: 36px;
  background-color: #0051b5;
  margin-left: 15px;
} */
.cen-map .item .txt {
  font-size: 22px;
  color: #fff;
  line-height: 1.5;
  margin-top: 20px;
  /* background: linear-gradient(  100%); */
  color: rgba(69, 245, 255, 1);
  padding-left: 20px;
}
/* .cen-map .item .pics {
  margin-left: -2%;
  margin-top: 20px;
  display: flex;
}
.cen-map .item .pic {
  width: 31.3%;
  margin-left: 2%;
} */
/* .cen-map .item .dz {
  font-size: 24px;
  color: #fff;
  margin-top: 20px;
} */
.cen-map .item .tel {
  display: flex;
  align-content: center;
  font-size: 28px;
  line-height: 64px;
  color: #fff;
  margin-top: 3%;
}
.warning1 {
  margin-top: 24px;
  margin-right: 12px;
  width: 16px;
  height: 16px;
  opacity: 1;
  border-radius: 50%;
  background: #45f5ff;
}
.warning2 {
  margin-top: 24px;
  margin-right: 12px;
  width: 16px;
  height: 16px;
  opacity: 1;
  background: red;
}
.cen-map .item .tel .tel-e {
  margin-bottom: 0;
  color: red;
  font-size: 48px;
}
.cen-map .item:nth-child(2) {
  /* opacity: 1; */
  top: 57%;
  left: 15%;
}
.cen-map .item:nth-child(3) {
  top: 55%;
  left: 6%;
}
.cen-map .item:nth-child(4) {
  top: 47%;
  left: 23%;
}
.cen-map .item:nth-child(5) {
  top: 45%;
  left: 23%;
}
.cen-map .item:nth-child(6) {
  top: 40%;
  left: 15%;
}
.cen-map .item:nth-child(7) {
  top: 35%;
  left: 36%;
}
.cen-map .item:nth-child(8) {
  top: 51%;
  left: 33%;
}
.cen-map .item:nth-child(9) {
  top: 71%;
  left: 35%;
}
.cen-map .item:nth-child(10) {
  top: 48%;
  left: 50%;
}
.cen-map .item:nth-child(11) {
  top: 35%;
  left: 36%;
}
.cen-map .item:nth-child(12) {
  top: 37%;
  left: 46%;
}
.cen-map .item:nth-child(13) {
  top: 35%;
  left: 45%;
}
</style>
